<template>
  <div>
    <div class="container">
      <hr class="mt-4 section-spacer" />
      <div class="heading mt-2 text-center">{{props.label}}</div>
      <div class="book-heading-big mt-3 text-center">{{props.title}}</div>
    </div>
    <div>
      <div class="growing-cover-slider">
      <UserProfileSlider :props="props.items" :componentId="id"/>
      </div>
    <router-link :to="{name: 'Authors', params: {id: bookData.autor_id}}" class="text-dark"><div class="book-heading-small mt-2 text-center">{{bookData.autor}}</div></router-link>
    <router-link :to="{name: 'BooksDetails', params: {id: bookData.id}}" class="text-dark"><div class="book-heading-big mt-2 text-center">{{bookData.tytul}}</div></router-link>
    <div class="stars-wrapper mt-1" v-if="bookData.rating > 0">
      <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
      inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
      :border-width='borderWidth' :show-rating="false" :read-only="true"
      :rounded-corners='rounded' :rating="bookData.rating" >
      </star-rating>
    </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../eventBus'
import UserProfileSlider from './UserProfileSlider'
import StarRating from 'vue-star-rating'
export default {
  name: 'BooksDetailsWrapper',
  props: {
    props: Object,
    id: String
  },
  components: {
    UserProfileSlider,
    StarRating
  },
  data () {
    return {
      compId: 'details',
      bookData: { autor: '', tytul: '', id: 0, rating: 0, autor_id: 0 },
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18
    }
  },
  methods: {
    updateData (payload) {
      this.bookData = payload
    },
    toBooks (e) {

    }
  },
  created () {
    EventBus.$on('books-in-' + this.id, (payload) => {
      this.updateData(payload)
    })
  },
  beforeDestroy () {
    EventBus.$off('books-in-details', (payload) => {
      this.updateData(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .stars-wrapper {
    display: flex;
    justify-content: center;
  }

  .heading{
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.63;
    text-align: center;
    margin: 0;
  }
</style>
