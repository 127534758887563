<template>
  <div>
    <TopMenuSubheadline v-if="!feed" />
    <div class="app-content-box bg-white" :class="{'padding-top-small': feed}" v-if="isLoaded">
      <div class="wrapper">
        <div class="container">
          <div class="book-cover">
            <img
              :src="thisBook.image ? (coverUrl + thisBook.image) : (coverUrl + 'no_cover.png')"
            />
            <router-link class="text-dark mt-3" :to="{name: 'Authors', params: {id: thisBook.author_id}}">
              <div class="book-heading-small text-center"><u>{{thisBook.autorzy}}</u></div>
            </router-link>
            <div class="book-heading-big mt-3 text-center">{{thisBook.tytul}}</div>
            <div class="icon-wrapper mt-3">
              <router-link v-if="bookInShelf == true" :to="{name: 'BorrowBook', params: {id: thisBook.id}}" class="icon text-dark">
                <span><div class="fas fa-book"></div></span>
              </router-link>
              <span @click="isPopupShown = true" v-if="bookInShelf == false" class="icon"><div class="fas fa-plus-circle"></div></span>
              <router-link :to="{name: 'BookfaceCreate', params: {book_id: thisBook.id}}" class="bookface-button mx-3">
                &nbsp;
              </router-link>
              <span @click="isPopupMore = true" class="icon"> <div class="far fa-paper-plane" @click="openMore"></div></span>
<!--              <div>-->
                <!-- <div class="fb-share-button"
                   data-href="https://developers.facebook.com/docs/plugins/"
                   data-layout="button_count" data-size="small"><a target="_blank"
                   :href="'https://www.facebook.com/sharer/sharer.php?u=http://bookme.dev.coreo.pl/book/' + id"
                   class="fb-xfbml-parse-ignore">Udostępnij</a></div> -->
<!--              </div>-->
              <!-- <div class="menu-wrapper"><div class="fas fa-ellipsis-v"></div></div> -->
            </div>
            <p class="text-left mt-3 heading-medium" v-if="isShortDesc && thisBook.opis.length">{{thisBook.opis.substring(0, 100)}}...</p>
            <p class="text-left mt-3 heading-medium" v-else>{{thisBook.opis}}</p>
            <span @click="isShortDesc = !isShortDesc" v-if="thisBook.opis.length"><strong><u>{{isShortDesc ? 'więcej' : 'mniej'}}</u></strong></span>
            <hr class="mt-4 section-spacer" />
            <div class="mt-3 mb-3">
              <div v-if="avgRating" class="heading mt-2 mb-2 text-center">Użytkownicy ocenili tę książkę</div>
              <div v-else class="heading mt-2 mb-2 text-center">Ta książka nie ma jeszcze oceny</div>
              <star-rating :increment="0.5" active-color="#000"
                inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
                :border-width='borderWidth' :animate='animate' :show-rating="false"
                :rounded-corners='rounded' :rating="rating" @rating-selected ="setRating" :star-size="30">
                </star-rating>
                <div class="rating-size text-center mt-3">
                  <div v-if="avgRating">Na: <span class="rating-large">{{avgRating}}</span> / 5 w skali Bookme</div>
                  <div v-else>Oceń ją!</div>
                  <div class="mt-2" v-if="myRating">Twoja ocena: {{myRating}}</div>
                </div>
            </div>
            <hr v-if="bookfaces.length" class="mt-4 mb-4 section-spacer" />

          </div>
        </div>

        <BookfaceSlider v-if="bookfaces.length" :bookfaces="bookfaces" :count="bookfaces.length" :book_id="id" :label="'#Bookface'" :sliderType="'book'"/>
        <div class="container" v-if="thisReview.items.length">
          <hr class="mt-4 section-spacer" />
          <div class="heading mt-2 text-center">{{thisReview.label}}</div>
          <div class="book-heading-big mt-3 text-center">{{thisReview.title}}</div>
        </div>
        <div class="mt-2" v-if="thisReview.items.length">
          <div class="growing-cover-slider tab-pane-slider swiper-container">
            <PeopleSliderReview :people="thisReview.items" id="review"/>
          </div>
          <div class="container">
            <p>{{reviewData.content.slice(0, 150)}}...<span @click="routeToReview"><b>więcej</b></span></p>
            <router-link :to="{name: 'ReviewAdd', params: {id: thisBook.id}}" class="w-100">
              <button class="btn-black-full w-100 mt-1">Napisz recenzję</button>
            </router-link>
          </div>
        </div>
        <div class="container">
          <div class="mt-1 mb-3 book-cover">
            <hr class="mt-4 section-spacer" v-if="thisInterested.length" />
            <div v-if="thisInterested.length">
              <div class="heading mt-2 text-center ">Zainteresowani czytelnicy</div>
              <div class="book-heading-big text-center mt-2">{{thisInterested.length}}</div>
            </div>
          </div>
        </div>
        <div class="mt-5" v-if="thisInterested.length">
          <div class="growing-cover-slider tab-pane-slider swiper-container">
            <PeopleSlider :people="thisInterested" id="interested"/>
          </div>
        </div>
        <BookDetailsWrapper :props="thisSimilar" v-if="thisSimilar.items.length" id="similar"/>
        <BookDetailsWrapper :props="thisAuthor" v-if="thisAuthor.items.length" id="author"/>
          <div class="container" v-if="thisCategory.items.length">
            <hr class="mt-4 mb-4 section-spacer" />
            <div class="top-heading-medium text-center">{{thisCategory.label}}</div>
            <Tags :tags="thisCategory.items"/>
          </div>
          <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal">
            <div class="text-center mb-2 modal-main-title">Dodaj książkę</div>
            <div class="modal-wrapper">
              <div class="growing-slide-cover">
                <img :src="( thisBook.image ? coverUrl + thisBook.image : coverUrl + 'no_cover.png')" class="slide-cover-image"/>
                </div>
                <div class="book-heading-big text-center mt-3">{{thisBook.autor}}</div>
                <div class="book-heading-small text-center mt-2">{{thisBook.tytul}}</div>
            </div>
            <router-link :to="{name: 'BorrowBook', params: {id: thisBook.id}}"><button class="btn-black-border w-100 mt-3 mb-1">Dodaj na półkę</button></router-link>
            <button class="btn-black-border w-100 mt-3 mb-1" @click="saveToLater">Zapisz na później</button>
            <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
          </ModalSlot>
          <ModalSlot title="Dodaj książkę" v-if="isPopupMore" @close-modal="closeMore">
            <router-link :to="{name: 'RecommendBook', params: {id: thisBook.id}}"><button class="btn-black-border w-100 mt-2 mb-1">Poleć znajomym</button></router-link>
            <!-- <button class="btn-black-full w-100 mt-2 mb-1" @click="shareOnFb">Udostępnij</button> -->
            <a target="_blank"
            :href="'https://www.facebook.com/sharer/sharer.php?u=https://' + plainUrl + '/book/' + id +'?action=share&type=book&user_id=' + my_id"
            class="btn-black-full w-100">Udostępnij</a>
            <button class="btn-black-border w-100 mt-2 mb-1" @click="closeMore(false)">Zamknij</button>
          </ModalSlot>
      </div>
    </div>
    <div class="bg-white" v-else>
      <Loader />
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import Loader from '../components/Loader'
import PeopleSlider from '../components/PeopleSlider'
import PeopleSliderReview from '../components/PeopleSliderReview'
import BookDetailsWrapper from '../components/BookDetailsWrapper'
import Tags from '../components/Tags'
import EventBus from '../eventBus'
import ModalSlot from '../components/ModalSlot'
import StarRating from 'vue-star-rating'
import BookfaceSlider from '../components/BookfaceSlider'
export default {
  name: 'BookDetails',
  components: {
    BookfaceSlider,
    TopMenuSubheadline,
    Loader,
    PeopleSlider,
    PeopleSliderReview,
    BookDetailsWrapper,
    Tags,
    ModalSlot,
    StarRating
  },
  props: {
    id: [Number, String],
    feed: Boolean
  },
  data () {
    return {
      isLoaded: false,
      isPopupShown: false,
      isPopupMore: false,
      bookInShelf: false,
      thisBook: [],
      thisAuthor: [],
      thisCategory: [],
      thisInterested: [],
      thisReview: [],
      thisSimilar: [],
      reviewData: { id: '', content: '' },
      isShortDesc: true,
      rating: 1,
      avgRating: 0,
      myRating: 0,
      borderWidth: 2,
      animate: true,
      rounded: true,
      bookfaces: [],
      bookfacePage: 1,
      bookfacesTotal: 0,
      fetchingBookfaces: false,
      loadMoreBookfaces: true
    }
  },
  computed: {
    my_id () { return this.$store.state.userData.userId }
  },
  async created () {
    await this.fetchData()

    EventBus.$on('review-content', (payload) => {
      this.updateData(payload)
    })

    // EventBus.$on('load-more-bookfaces', this.getBookfaces)
  },
  watch: {
    $route () {
      this.isLoaded = false
      this.bookInShelf = false
      this.fetchData()
    }
  },
  methods: {
    shareOnFb () {
      window.location = 'fb://dialog/'
    },
    openMore () {
      this.openMore = true
    },
    closeMore () {
      this.isPopupMore = false
    },
    saveToLater () {
      const getData = 'status=later&own=1&borrowed=0'
      this.$https('book/' + this.id + '/add/' + 0, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.closeModal()
      })
        .catch(error => {
          console.log('', error)
        })
    },
    setRating (rating) {
      // this.rating = rating
      const getData = `value=${rating}`
      this.$https('/rate/book/' + this.id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.isLoaded = false
        this.fetchData()
      })
        .catch(error => {
          console.log('', error)
        })
    },
    routeToReview () {
      this.$router.push({ name: 'ReviewDetails', params: { id: this.reviewData.id, book_id: this.id } })
    },
    closeModal () {
      this.isPopupShown = false
    },
    openModal () {
      this.isPopupShown = true
    },
    updateData (payload) {
      this.reviewData = payload
    },
    fetchData () {
      this.$https('/book/' + this.id, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(res => {
          this.thisBook = res.data.response.book
          this.rating = parseFloat(this.thisBook.rating, 10)
          this.avgRating = this.thisBook.rating
          if (this.avgRating % 1 === 0) {
            this.avgRating = parseFloat(this.thisBook.rating, 10)
          } else {
            this.avgRating = parseFloat(this.thisBook.rating)
            this.avgRating = this.avgRating.toFixed(1)
          }
          this.myRating = this.thisBook.myRating
          this.thisInterested = res.data.response.interested.items
          this.thisReview = res.data.response.review
          this.thisSimilar = res.data.response.similar
          this.thisAuthor = res.data.response.author
          this.thisCategory = res.data.response.category
          // this.bookfaces = res.data.response.bookface.items
          this.getBookfaces()
          this.isLoaded = true
          if (this.thisBook.info.added) {
            this.bookInShelf = true
          } else {
            this.bookInShelf = false
          }
        })
        .catch(error => {
          console.log('', error)
        })
    },
    getBookfaces () {
      if (!this.fetchingBookfaces) {
        this.fetchingBookfaces = true
        const data = new FormData()
        data.append('id', this.id)
        data.append('type', 'book')
        data.append('page', this.bookfacePage)
        this.$https.post('/bookface/get-bookfaces', data, {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
          .then(res => {
            // const items = res.data.response.items
            this.bookfaces = res.data.response

            // if (this.bookfaces.length >= this.bookfacesTotal) {
            //   this.loadMoreBookfaces = false
            // }
            //
            // if (this.loadMoreBookfaces) {
            //   items.forEach(element => {
            //     this.bookfaces.push(element)
            //   })
            //   this.bookfacePage = this.bookfacePage + 1
            //   EventBus.$emit('update-swiper', false)
            // }
            this.fetchingBookfaces = false
          })
          .catch(error => {
            console.log('', error)
          })
      }
    }
  },
  beforeDestroy () {
    EventBus.$off('review-content', (payload) => {
      this.updateData(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .heading{
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.63;
    text-align: center;
    margin: 0;
  }
  .wrapper {
    margin-top: 40px;
    margin-bottom: 50px;
  }
  .book-cover {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .book-cover img {
    width: 190px;
    height: 270px;
  }
  .icon-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .icon{
      font-size: 27px;
    }
  }
  .menu-wrapper {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  .modal-wrapper img {
    max-width: 200px;
    max-height: 300px;
  }
  .rating-size {
    font-size: 16px;
  }
  .padding-top-small{
    padding-top: 1px;
  }
  .bookface-button{
    background: transparent url(../assets/images/bf-add.svg) no-repeat center;
    background-size: cover;
    width: 45px;
    height: 53px;
  }
  .rating-size{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    .rating-large{
      font-size: 28px;
    }
  }

  .vue-star-rating{
    justify-content: center;
  }
</style>
