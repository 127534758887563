<template>
  <div>
    <swiper ref="mySwiper" :options="swiperOptions" @slideChange="sendData" @ready="sendData">
    <div class="people-slider-avatar growing-slide swiper-slide slide-wrapper" v-for="(person, key) in people" :key="key">
      <swiper-slide>
          <div class="growing-slide-avatar-container books-featured-slide-avatar-container" v-if="my_id != person.user_id">
            <div class="growing-slide-avatar bottom-add-sign">
              <div>
                <router-link :to="{name: 'OtherUserProfile', params: {id: person.user_id ? person.user_id : person.id}}">
                  <img
                    :src="person.thumb_url ? plainUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')"
                    class="people-slider-avatar"
                    :alt="person.name"
                  />
                </router-link>
              </div>
              <div @click="addFriend(person)">
               <span class="remove-button-small" v-if="person.is_friend"></span>
                <span class="add-sign-button-small" v-else></span>
              </div>
            </div>
          </div>
          <div class="growing-slide-avatar-container books-featured-slide-avatar-container" v-else>
            <div class="growing-slide-avatar bottom-add-sign">
              <div>
                <router-link :to="{name: 'UserProfile'}">
                  <img
                    :src="person.thumb_url ? plainUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')"
                    class="people-slider-avatar"
                    :alt="person.name"
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div class="text-center top-heading-small">{{person.username}}</div>
      </swiper-slide>
    </div>
  </swiper>
      <ModalAddFriend  v-if="isModalOpen && isLoaded"
        @close-modal="closeModal"
        :userdata="modalData"
        :isFriend="isFriend"
          />
</div>
</template>
<script>
import { Swiper, SwiperSlide } from '../../node_modules/vue-awesome-swiper'
import ModalAddFriend from '../components/ModalAddFriend'
import EventBus from '../eventBus'
export default {
  name: 'PeopleSliderReview',
  props: {
    people: Array
  },
  components: {
    Swiper,
    SwiperSlide,
    ModalAddFriend
  },
  data () {
    return {
      isModalOpen: true,
      isFriend: '',
      modalData: [],
      slideIndex: 0,
      isLoaded: false,
      swiperOptions: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        observer: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false
        }
      }
    }
  },
  computed: {
    my_id () { return this.$store.state.userData.userId },
    swiper () { return this.$refs.mySwiper.$swiper }
  },
  watch: {
    people: function () {
      this.swiper.slideTo(0, 500, true)
      this.id = this._uid
      this.sendData()
    }
  },
  methods: {
    sendData () {
      this.slideIndex = this.$refs.mySwiper.$swiper.activeIndex
      var el = this.people[this.slideIndex]
      if (el.content) {
        const payload = {
          id: el.id,
          content: el.content
        }
        EventBus.$emit('review-content', payload)
      }
    },
    closeModal (e) {
      this.isModalOpen = false
    },
    addFriend (person) {
      this.isLoaded = false
      this.modalData = []
      this.modalData.push({
        id: person.id,
        thumb_url: person.thumb_url,
        username: person.username
      })
      if (person.id === this.my_id) {
      } else if (person.is_friend == null) {
        this.isLoaded = true
        this.isFriend = true
        this.isModalOpen = true
      } else {
        this.isFriend = false
        this.isModalOpen = true
        this.isLoaded = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .swiper-container {
    padding: 20px 0 0 0;
  }
  .people-slider-avatar {
    width: 190px;
    height: 190px;
  }
    .growing-slide-avatar-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    height: 200px;
    padding: 15px 0 0;

    &.books-featured-slide-avatar-container {
      padding-bottom: 25px;
    }

    img {
      width: 100%;
    }
  }
  .growing-slide-avatar {
    img {
      border-radius: 50%;
    }
  }
</style>
