<template>
  <div class="tags-box">
    <ul>
      <li v-for="(tag, key) in tags" :key="key">
        <router-link :to="{name: 'TagsDetails', params: {id: tag.id}}">
          <span>{{ tag.pl }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Tags',
  props: {
    tags: Array
  }
}
</script>
<style lang="scss" scoped>
</style>
